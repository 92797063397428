<template>
  <div class="logo-area">
    <div class="logo-ress">
    <img class="logo" :src="getImgUrl('logo-ress.png')" alt="" />
    </div>
    <div class="logo-social">
      <div>Apoio</div>
      <img class="social" :src="getImgUrl('logo-social.png')" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "logo-area",
  methods: {
    getImgUrl(icone) {
      return require(`../../assets/images/${icone}`);
    },
  },
};
</script>

<style lang="scss">
.logo-area {
  display: flex;
  align-items: center;
  & img {
    &.logo {
      height: 75px;
    }
  }
  & img {
    &.social {
      margin-left: 20px;
      height: 50px;
      display: flex;
    }
  }
}

@media only screen and (max-width: 425px) {
  .logo-area {
    flex-direction: column;
    & img.social{
      margin-left: 0;
    }
  }
}
</style>
