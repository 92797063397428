<template>
  <div class="carousel-not" v-if="items.length">
    <el-carousel
      indicator-position="none"
      arrow="always"
      v-if="items.length > 0"
      :interval="5000"
    >
      <el-carousel-item
        v-for="item in items"
        :key="item.id"
        @click.native="handleShow(item)"
      >
        <!-- <img :src="item.image" alt="" /> -->
        <Card :destaque="item" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import Card from "@/components/destaque/Card";
export default {
  name: "carousel-noticias",
  components: {
    Card,
  },
  props: ["items", "handleShowDestaque"],
  methods: {
    handleShow(item) {
      console.log("in handle show", item);
      this.$emit("handleShowDestaque", item);
    },
  },
};
</script>

<style lang="scss">
.carousel-not {
  .el-carousel__container {
    height: 524px !important;
    max-width: 100%;
  }
  .el-carousel__item img {
    height: 524px;
    margin: 0;
    object-fit: cover;
    object-position: top;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .el-carousel__arrow {
    height: 57px;
    width: 57px;
    // font-size: 26px;
    font-size: calc(var(--font-size--small) * 2);
    font-weight: bold;
    color: #d3dce6;
  }
}

@media only screen and (max-width: 1224px) {
  .carousel-not {
    .el-carousel__container {
      height: 405px !important;
    }
    .el-carousel__item img {
      height: 405px;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}
// // @media only screen and (max-width: 768px) {
// //   .el-carousel__container {
// //     height: 324px !important;
// //   }
// //   .el-carousel__item img {
// //     height: 324px;
// //     width: 100%;
// //   }
// // }
@media only screen and (max-width: 650px) {
  .carousel-not {
    .el-carousel__container {
      height: 372px !important;
    }
    .el-carousel__item img {
      height: 372px;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}
</style>
